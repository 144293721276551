import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ptBR } from 'date-fns/locale'
import { format } from 'date-fns'
import { FIREBASE_EVENTS } from 'app-constants'
import { ContextLoader } from 'components'
import { getTickets, useFirebase } from 'hooks'
import './style.scss'

export function MyTickets() {
  const [tickets, setTickets] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)
  const { sendEvent } = useFirebase()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.SERVICE_ORDER_INITIAL)
  }, [sendEvent])

  useEffect(() => {
    async function getInfoTickets() {
      try {
        const response = await getTickets(idContract)

        setTickets(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    getInfoTickets()
  }, [idContract])

  function renderTicketHeader() {
    return (
      <div className='header'>
        <span className='column-title'>
          <strong>Abertura</strong>
        </span>
        <span className='column-title'>
          <strong>Protocolo</strong>
        </span>
        <span className='column-title'>
          <strong>Motivo</strong>
        </span>
        <span className='column-title'>
          <strong>Status</strong>
        </span>
      </div>
    )
  }

  function renderTickets() {
    return tickets.map((ticket, index) => {
      const { scheduleDate, id, motive, status } = ticket
      const formattedSchedule = format(new Date(scheduleDate), 'dd/MM/yyyy', { locale: ptBR })
      const ticketsQuantity = tickets.length
      const isLast = index === ticketsQuantity - 1

      return (
        <div className={`container-infos ${isLast ? 'last' : ''}`}>
          <div className='container-info'>
            <span className='info'>{formattedSchedule}</span>
          </div>
          <div className='container-info'>
            <span className='info'>{id}</span>
          </div>
          <div className='container-info'>
            <span className='info'>{motive}</span>
          </div>
          <div className='container-info'>
            <span className='info'>{status}</span>
          </div>
        </div>
      )
    })
  }

  function renderTicketTableOrWarning() {
    const hasTickets = tickets.length > 0

    if (!hasTickets)
      return (
        <p className='subtitle message'>
          Não encontramos chamados para o seu contrato nos últimos 90 dias.
        </p>
      )

    return (
      <>
        {renderTicketHeader()}
        {renderTickets()}
      </>
    )
  }

  if (isLoading) return <ContextLoader fixed={false} />

  return (
    <div className='my-services-component custom-drawer'>
      <h3 className='title'>Ordens de serviço</h3>
      <div className='contet-wrapper'>{renderTicketTableOrWarning()}</div>
    </div>
  )
}

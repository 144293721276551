import React, { useEffect, useState } from 'react'
import { Accordion } from 'components/accordion'
import { useFirebase } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { FAQ_CATEGORIES_LABELS } from '../../constants'
import './style.scss'

export function FaqCategory({ faq, category }) {
  const [categoryFaq, setCategoryFaq] = useState([])
  const { sendEvent } = useFirebase()

  useEffect(() => {
    const filteredFaq = faq.filter(({ category: currentCategory }) => currentCategory === category)
    setCategoryFaq(filteredFaq)
  }, [category, faq])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.FAQ_QUESTIONS_LIST_VIEW)
  }, [sendEvent])

  function renderQuestions() {
    return categoryFaq.map(({ question, answer }) => (
      <Accordion title={question} description={answer} />
    ))
  }

  return (
    <div className='faq-categories-component'>
      <h3 className='title'>Perguntas Frequentes</h3>
      <strong className='subtitle'>{FAQ_CATEGORIES_LABELS[category]}</strong>
      {renderQuestions()}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CountdownTime, GenericCard } from 'components'
import { setDrawer } from 'slices/drawer'
import { finishProgress, setTimer, updateFirstTimerStatus } from 'slices/wifi'
import { fetchInternet } from 'slices/contract'
import { FIREBASE_EVENTS, MILLISECONDS_MULTIPLIER, ONE_MINUTE } from 'app-constants'
import { fetchMessages } from 'slices/customer'
import { setModal } from 'slices/modal'
import { useFirebase } from 'hooks'

export function ModemCard({ className, title, buttonLabel, Icon, description }) {
  const [timeoutId, setTimeoutId] = useState(null)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const {
    internet,
    selected: { id: idContract },
    countdownTimer,
    firstTimerFinished,
    inProgress,
    messages,
  } = useSelector(({ contract, wifi, customer }) => ({
    ...contract,
    ...wifi,
    ...customer,
  }))

  useEffect(() => {
    dispatch(setTimer())
  }, [dispatch])

  useEffect(() => {
    function fullTimeout() {
      dispatch(fetchMessages())
      dispatch(setDrawer(''))
    }

    function oneMinuteTimeout() {
      dispatch(fetchInternet(idContract))
      dispatch(updateFirstTimerStatus(true))
    }

    function initializeTimers() {
      if (inProgress && countdownTimer) {
        const timeout = setTimeout(fullTimeout, [countdownTimer * MILLISECONDS_MULTIPLIER])
        setTimeoutId(timeout)

        if (countdownTimer > ONE_MINUTE) {
          const firstTimerCount = countdownTimer - ONE_MINUTE
          setTimeout(oneMinuteTimeout, [firstTimerCount * MILLISECONDS_MULTIPLIER])
        }
      }
    }

    initializeTimers()
  }, [dispatch, inProgress, idContract, countdownTimer])

  useEffect(() => {
    function validateTimerProgress() {
      if (firstTimerFinished && inProgress && !internet?.hasConfigurationInProgress) {
        dispatch(fetchMessages())
        dispatch(setDrawer(''))
      }
    }

    validateTimerProgress()
  }, [dispatch, inProgress, internet, firstTimerFinished, timeoutId])

  useEffect(() => {
    const existsMandatoryUnreadMessage = messages.contentUnread.some(msg => msg.mandatory)
    if (firstTimerFinished && existsMandatoryUnreadMessage) {
      clearTimeout(timeoutId)
      dispatch(finishProgress())
      dispatch(setModal({ key: 'messages', locked: true }))
    }
  }, [dispatch, firstTimerFinished, messages, timeoutId])

  function handleDrawer() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_WIFI_UPDATE)
    dispatch(setDrawer('modem'))
  }

  function renderCounter() {
    return (
      <>
        Restam <CountdownTime time={countdownTimer} /> Segundos
      </>
    )
  }

  return (
    <GenericCard
      className={className}
      title={title}
      buttonLabel={inProgress ? renderCounter() : buttonLabel}
      Icon={Icon}
      onClick={handleDrawer}
    >
      {inProgress ? 'Aguarde enquanto a sua solicitação é processada' : description}
    </GenericCard>
  )
}

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMassiveWarningByUnityAndContract } from 'hooks'

const initialState = {
  virtualSupportMassive: null,
  isLoading: true,
}

export const fetchMassiveWarningByUnityAndContractId = createAsyncThunk(
  'massive/fetchMassiveWarningByUnityAndContractId',
  async (unity, contractId) => {
    try {
      // TODO lucas.santos: Incluir chamada para getMassiveWarningByOlt quando disponível pelo backend
      const { data } = await getMassiveWarningByUnityAndContract(unity, contractId)
      return data
    } catch (error) {
      console.error(error)
      return null
    }
  }
)

export const massiveSlice = createSlice({
  name: 'massive',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchMassiveWarningByUnityAndContractId.fulfilled, (state, action) => {
        state.virtualSupportMassive = action.payload
        state.isLoading = false
      })
      .addCase(fetchMassiveWarningByUnityAndContractId.rejected, state => {
        state.isLoading = false
      })
  },
})

export default massiveSlice.reducer

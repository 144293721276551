import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FIREBASE_EVENTS, VIRTUAL_SUPPORT_STAGES } from 'app-constants'
import { Button, Card } from 'components'
import { setDrawer, setStage } from 'slices/drawer'
import { getMassiveMessage } from 'utils'
import './style.scss'
import { useFirebase } from 'hooks'

export function MassiveWarning({ variantWarning }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const { virtualSupportMassive } = useSelector(({ massive }) => massive)

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.VIRTUAL_SUPPORT_LOCATION_WITH_MASSIVE)
  }, [sendEvent])

  function handleClose() {
    dispatch(setDrawer(''))
  }

  function handleContinue() {
    if (variantWarning) {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.REDIRECT_CHAT))
    } else {
      dispatch(setStage(VIRTUAL_SUPPORT_STAGES.AUTO_SUPPORT))
    }
  }

  function renderMassiveWarning() {
    const { timeLeft, energyFailure, sabotageFailure } = virtualSupportMassive
    const { title, description } = getMassiveMessage(
      sabotageFailure,
      energyFailure,
      timeLeft,
      false
    )

    return (
      <>
        <p className='card-title'>{title}</p>
        <p className='text'>{description}</p>
      </>
    )
  }

  function renderButtons() {
    return (
      <div className='links'>
        <Button
          className='button'
          color='primary'
          size='large'
          onClick={() => {
            handleClose()
          }}
        >
          Ok, entendi!
        </Button>
        <Button
          className='button'
          color='primary'
          size='large'
          outlined
          onClick={() => {
            handleContinue()
          }}
        >
          {variantWarning ? 'Falar com atendente' : 'Ainda preciso de suporte técnico'}
        </Button>
      </div>
    )
  }

  return (
    <div className='virtual-support-info'>
      <div className='content'>
        <h3 className='title'>Central de notificação</h3>
      </div>
      <Card className='massive-waring-card'>{renderMassiveWarning()}</Card>
      {renderButtons()}
    </div>
  )
}

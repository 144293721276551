import React from 'react'
import { STATICS } from 'statics'
import { useFirebase } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { SigmaChatBot } from '../sigma-chat'
import './style.scss'

export function Chat() {
  const { sendEvent } = useFirebase()

  function handleClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_CHAT_BUTTON)
    window.SigmaChat.showChat()
  }

  return (
    <>
      <SigmaChatBot />
      <button id='fixed-button' className='chat-button' onClick={handleClick}>
        <STATICS.ONLINE_CHAT alt='Chat' />
      </button>
    </>
  )
}

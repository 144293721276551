import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useFirebase, useForm } from 'hooks'
import { addCustomerContact } from 'slices/customer'
import { setDrawerAlert, setStage } from 'slices/drawer'
import {
  REGISTRATION_DATA_STAGES,
  CONTACT_TYPE,
  DEFAULT_ERROR_MESSAGE,
  FIREBASE_EVENTS,
} from 'app-constants'
import { Button, Input } from 'components'
import './style.scss'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato inválido')
    .required('Campo obrigatório'),
})

export function EmailAdd({ data, setData }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const form = useForm({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  async function handleSubmit(values) {
    const { email } = values
    sendEvent(FIREBASE_EVENTS.ADD_EMAIL_INFORM_NEW_EMAIL)

    try {
      const { newContactId } = await dispatch(
        addCustomerContact({
          contact: email,
          contactType: CONTACT_TYPE.EMAIL,
        })
      ).unwrap()

      setData({
        ...data,
        contactId: newContactId,
        contact: email,
      })

      dispatch(setStage(REGISTRATION_DATA_STAGES.CONFIRMATION))
    } catch (error) {
      const errorMessage = error?.message || DEFAULT_ERROR_MESSAGE

      dispatch(setDrawerAlert({ severity: 'error', title: errorMessage }))
    }
  }

  return (
    <form className='email-add-component' onSubmit={form.handleSubmit}>
      <h3 className='title'>Adicione um novo e-mail</h3>
      <h5 className='subtitle'>
        Deseja incluir mais um e-mail ao seu cadastro? Insira o e-mail abaixo:
      </h5>
      <label className='label'>Insira seu novo e-mail</label>
      <Input
        className='input'
        name='email'
        label='Novo e-mail'
        onChange={form.handleChange}
        value={form.values.email}
        hasError={form.errors.email}
        errorMessage={form.errors.email}
      />
      <Button
        className='button'
        type='submit'
        color='primary'
        size='large'
        isLoading={form.isSubmitting}
      >
        Continuar
      </Button>
    </form>
  )
}

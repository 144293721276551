import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from 'slices/modal'
import { unlockAccount } from 'slices/contract'
import { EVENT_STATUS, EVENTS, FIREBASE_EVENTS, PLAN_STATUS_KEY } from 'app-constants'
import { getIsEligibleToUnlockContract, postEvent, useFirebase } from 'hooks'
import { STATICS } from 'statics'
import { ModalBody } from './components'
import './style.scss'

export function UnlockAccountModal() {
  const [loading, setLoading] = useState(false)
  const {
    selected: { id: idContract, suspendedByDebit, contractStatus },
    unlockDate,
  } = useSelector(state => state.contract)
  const dispatch = useDispatch()
  const history = useHistory()
  const { sendEvent } = useFirebase()

  function handleClose() {
    postEvent(idContract, EVENTS.CLICK_ON_OK_UNLOCK_BY_TRUST)
    dispatch(setModal({ key: '' }))
  }

  function handleGoToInvoices() {
    handleClose()
    history.push('minhas-faturas')
  }

  async function handleUnlock() {
    postEvent(idContract, EVENTS.UNLOCK_BY_TRUST)
    sendEvent(FIREBASE_EVENTS.CLICK_ON_ACCOUNT_UNLOCK_NOW)

    try {
      setLoading(true)
      const { data } = await getIsEligibleToUnlockContract(idContract)

      if (!data.eligibleToUnlock) {
        sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_NOT_ELIGIBLE)
        dispatch(setModal({ key: 'unlock_account_error' }))
      } else {
        await dispatch(unlockAccount({ idContract })).unwrap()
        sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_SUCCESS, EVENT_STATUS.SUCCESS)
      }
    } catch (error) {
      sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_ERROR, EVENT_STATUS.ERROR, error?.response?.status)
      dispatch(setModal({ key: 'unlock_account_error' }))
    } finally {
      s
      setLoading(false)
    }
  }

  function renderStatusCanUnlock() {
    return (
      <ModalBody
        buttonLabel='Desbloquear agora'
        staticIcon={STATICS.ROUNDED_LOCK}
        info={`O desbloqueio durará por 24 horas. Efetue o pagamento pendente\npara não ser bloqueado novamente.`}
        backgroundColor='red'
        loading={loading}
        onClick={handleUnlock}
        subTitle={`Esqueceu de pagar sua fatura?\nNão tem problema, nós liberamos o bloqueio\naté efetuar o pagamento.*`}
        title={`Sua conta se encontra\nbloqueada no momento!`}
      />
    )
  }

  function renderStatusTemporaryUnlocked() {
    return (
      <ModalBody
        buttonLabel='Ir para minhas faturas'
        staticIcon={STATICS.ROUNDED_LOCK_OPEN}
        backgroundColor='lightBlue'
        onClick={handleGoToInvoices}
        subTitle={`Efetue o pagamento pendendo para não voltar\na ser bloqueado`}
        title={`Sua conta foi desbloqueada\ntemporariamente!`}
      />
    )
  }

  function renderStatusBlocked() {
    return (
      <ModalBody
        buttonLabel='Ir para minhas faturas'
        staticIcon={STATICS.ROUNDED_LOCK}
        onClick={handleGoToInvoices}
        subTitle={`Efetue o pagamento pendente para voltar a ter acesso\na sua conta. Caso já tenha efetuado o pagamento,\naguarde a compensação bancária.`}
        title={`Sua conta se encontra\nbloqueada no momento!`}
      />
    )
  }

  function renderStatusActivePending() {
    return (
      <ModalBody
        buttonLabel='Ok, entendi'
        staticIcon={STATICS.ROUNDED_LOCK}
        onClick={handleClose}
        subTitle={`Essa funcionalidade está bloqueada até\nque a instalação seja concluída`}
        title='Funcionalidade indisponível'
      />
    )
  }

  function renderStatusUnlocked() {
    sendEvent(FIREBASE_EVENTS.UNLOCK_ACCOUNT_UNBLOCKED_ACCOUNT)

    return (
      <ModalBody
        backgroundColor='green'
        buttonLabel='Ok, entendi'
        staticIcon={STATICS.DONE}
        onClick={handleClose}
        title={`Sua conta não se encontra\nbloqueada!`}
      />
    )
  }

  function renderModalBodyByStatus() {
    if (suspendedByDebit && !unlockDate) {
      return renderStatusCanUnlock()
    }
    if (suspendedByDebit && unlockDate) {
      return renderStatusTemporaryUnlocked()
    }
    if (suspendedByDebit) {
      return renderStatusBlocked()
    }
    if (contractStatus === PLAN_STATUS_KEY.ST_CONT_EM_ATIVACAO) {
      return renderStatusActivePending()
    }
    return renderStatusUnlocked()
  }

  return <div className='unlock-account-component'>{renderModalBodyByStatus()}</div>
}

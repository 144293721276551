/* eslint-disable react/no-danger */
import React, { useRef, useState } from 'react'
import { STATICS } from 'statics'
import './style.scss'

export function Accordion({ title = '', description = '' }) {
  const [isOpen, setIsOpen] = useState(false)
  const contentHeight = useRef()

  return (
    <div className={`accordion-component ${isOpen && '-open'}`}>
      <button className='header-button' type='button' onClick={() => setIsOpen(!isOpen)}>
        {title}
        <div className='icon'>
          <STATICS.ARROW_DOWN />
        </div>
      </button>
      <div
        ref={contentHeight}
        className='body-content'
        style={{
          height: isOpen ? contentHeight.current.scrollHeight : '0px',
        }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

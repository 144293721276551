import { Button } from 'components'

export function ModalBody({
  backgroundColor = 'red',
  staticIcon: StaticIcon,
  subTitle = '',
  title = '',
  onClick = () => {},
  loading = false,
  info = '',
  buttonLabel = '',
}) {
  return (
    <>
      <div className={`round -${backgroundColor}`}>
        <StaticIcon className='icon' />
      </div>
      <h4 className={`title -${!subTitle ? 'paddingBottom' : ''}`}>{title}</h4>
      {subTitle && <h5 className='subtitle'>{subTitle}</h5>}

      <Button
        type='button'
        className='button'
        color='primary'
        size='large'
        onClick={onClick}
        loading={loading}
      >
        {buttonLabel}
      </Button>

      {info && <p className='info'>{info}</p>}
    </>
  )
}

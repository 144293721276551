import React from 'react'
import { useSelector } from 'react-redux'
import { MyTickets } from './components'

function stages() {
  return {
    initial: <MyTickets />,
  }
}

export function MyTicketsDrawer() {
  const { stage } = useSelector(({ drawer }) => drawer)

  return stages()[stage]
}

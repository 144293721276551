import { RIBEIRAO_PRETO_UNITY, RIBEIRAO_PRETO_WHATS_APP, WHATSAPP_NUMBER } from 'app-constants'

function getWhatsAppNumber(unity) {
  if (unity === RIBEIRAO_PRETO_UNITY) {
    return RIBEIRAO_PRETO_WHATS_APP
  }

  return WHATSAPP_NUMBER
}

export function sendWhatsappMessage(message, unity) {
  const messageEncoded = window.encodeURIComponent(message)
  const whatsappNumber = getWhatsAppNumber(unity)
  const WHATSAPP_SUMICITY_LINK = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${messageEncoded}`
  window.open(WHATSAPP_SUMICITY_LINK, '_blank')
}

import React from 'react'
import {
  DISRUPTION_FLAW_INITIAL,
  DISRUPTION_FLAW_TIME_LEFT,
  DISRUPTION_FLAW_FINAL,
  DISRUPTION_FLAW_INSTRUCTION,
  MASSIVE_MESSAGE_TITLE,
  ENERGY_FLAW,
  SABOTAGE_FLAW,
} from 'app-constants'

function getTimeLeftMessage(timeLeft) {
  if (timeLeft)
    return (
      <>
        {DISRUPTION_FLAW_TIME_LEFT} <strong>{timeLeft}</strong>
      </>
    )

  return null
}

function getSupportVirtualMessage(timeLeft, hasInstructions) {
  const description = (
    <>
      {DISRUPTION_FLAW_INITIAL}
      {getTimeLeftMessage(timeLeft)}
      {DISRUPTION_FLAW_FINAL} {hasInstructions && DISRUPTION_FLAW_INSTRUCTION}
    </>
  )
  const title = MASSIVE_MESSAGE_TITLE

  return { description, title }
}

export function getMassiveMessage(
  isSabotageFailure,
  isEnergyFailure,
  timeLeft,
  hasInstructionshasInstructions
) {
  const massiveInfo = { title: MASSIVE_MESSAGE_TITLE, description: '' }
  const isDefaultMassive = !isSabotageFailure && !isEnergyFailure

  if (isDefaultMassive) return getSupportVirtualMessage(timeLeft, hasInstructionshasInstructions)

  massiveInfo.description = isSabotageFailure ? SABOTAGE_FLAW : ENERGY_FLAW
  massiveInfo.description = `${massiveInfo.description} ${
    hasInstructionshasInstructions ? DISRUPTION_FLAW_INSTRUCTION : ''
  }`

  return massiveInfo
}

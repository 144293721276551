import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal } from 'slices/modal'
import { STATICS } from 'statics'
import { Button } from 'components/button'
import './style.scss'

export function UnlockAccountErrorModal() {
  const dispatch = useDispatch()

  function handleClose() {
    dispatch(setModal({ key: '' }))
  }

  return (
    <div className='unlock-account-error-component'>
      <div className='round'>
        <STATICS.CLOSE className='icon' />
      </div>
      <h4 className='title'>{`Não foi possível realizar o desbloqueio da\nsua conta.`}</h4>
      <h5 className='subtitle'>
        {`Para mais detalhes, entre em contato através\ndo 10353 ou pelo nosso chat.`}
      </h5>
      <Button type='button' className='button' color='primary' size='large' onClick={handleClose}>
        Ok, entendi
      </Button>
    </div>
  )
}

import { STATICS } from 'statics'
import { DASHBOARD_COMPONENTS_NAMES } from '../common/dashboard'

export const DASHBOARD_COMPONENTS = [
  {
    type: DASHBOARD_COMPONENTS_NAMES.CHAT,
    props: {},
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.BILLS_CARD,
    props: {
      className: 'bills',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.PERSONAL_INFO_CARD,
    props: {
      className: 'info',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.ADDRESS_CARD,
    props: {
      className: 'address',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.NEGOTIATION_CARD,
    props: {
      className: 'generic',
      title: 'Negociar Faturas',
      Icon: STATICS.BILL_CHECK,
      description: 'Negocie suas fatura em aberto',
      buttonLabel: 'Saiba Mais',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.UNLOCK_CARD,
    props: {
      className: 'generic',
      title: 'Desbloqueio de conta',
      Icon: STATICS.LOCK,
      description: 'Sua conta foi bloqueada?',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.SUBSCRIPTION_CARD,
    props: {
      className: 'subscription',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.FILE_UPLOAD_CARD,
    props: {
      className: 'generic',
      title: 'Envio de arquivo',
      buttonLabel: 'envio',
      Icon: STATICS.UPLOAD,
      description: 'Faça upload do seu arquivo',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.MODEM_CARD,
    props: {
      className: 'generic',
      title: 'Configurações do Wi-fi',
      Icon: STATICS.WRENCH,
      description: 'Acesse as configurações do modem',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.PHONE_CARD,
    props: {
      className: 'phone',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.VIRTUAL_SUPPORT,
    props: {
      className: 'generic',
      title: 'Suporte técnico',
      Icon: STATICS.WIFI,
      description: 'Está com problemas técnicos?',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.SPEED_TEST,
    props: {
      className: 'generic',
      title: 'Teste de Velocidade',
      Icon: STATICS.SPEED_TEST,
      description: 'Teste a velocidade da sua conexão',
      buttonLabel: 'Testar',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.MYSERVICES_CARD,
    props: {
      className: 'generic',
      title: 'Serviços digitais',
      Icon: STATICS.MY_SERVICES,
      description: 'Acesse os serviços que estão disponíveis em sua conta',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.MYCALLS_CARDS,
    props: {
      className: 'generic',
      title: 'Ordens de serviço',
      Icon: STATICS.CONTACT_EMAIL,
      description: 'Veja as informações básicas sobre suas ordens de serviço',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.INDICATE_FRIEND,
    props: {
      className: 'genericlarge',
      title: 'Indicou, ganhou!',
      description:
        'Ganhe 100% de desconto na próxima fatura* indicando um amigo com o seu cupom exclusivo:',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.PASSWORD_CARD,
    props: {
      className: 'generic',
      title: 'Alterar senha',
      Icon: STATICS.KEY,
      description: 'Altere sua senha da central do assinante',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.FAQ_CARD,
    props: {
      className: 'generic',
      title: 'Perguntas frequentes',
      buttonLabel: 'saiba mais',
      Icon: STATICS.QUESTION,
      description: 'Tire suas dúvidas em nosso FAQ',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.PREMIERE,
    props: {
      className: 'genericlarge',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_CARD,
    props: {
      className: 'genericlarge',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.TELECINE_CARD,
    props: {
      className: 'genericlarge',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.GLOBOPLAY_PLUS_CARD,
    props: {
      className: 'genericlarge',
    },
  },
  {
    type: DASHBOARD_COMPONENTS_NAMES.GIGA_TV_CARD,
    props: {
      className: 'genericlarge',
    },
  },
]

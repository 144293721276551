/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  activateHero,
  getContractDetails,
  getInternet,
  getTelephony,
  togglePaidCall,
  unlockContracts,
  updateWifi,
  getConnectionStatusRequest,
} from 'hooks'
import { formatCurrency, toISO } from 'utils'

const initialState = {
  selected: null,
  detail: null,
  motv: null,
  internet: null,
  telephony: null,
  unlockDate: null,
  connectionStatus: null,
}

export const fetchContractDetails = createAsyncThunk('contract/fetchContractDetails', async id => {
  const { data } = await getContractDetails(id)

  const payload = {
    ...data,
    formattedAdditionalTvPointsTotalPrice: formatCurrency(data.additionalTvPointsTotalPrice),
    formattedTotalPrice: formatCurrency(data.totalPrice),
    contractAdditionals: data.contractAdditionals.map(item => ({
      ...item,
      formattedItemPrice: formatCurrency(item.itemPrice),
    })),
  }

  return payload
})

export const fetchInternet = createAsyncThunk('contract/fetchInternet', async id => {
  const { data } = await getInternet(id)

  return data
})

export const fetchTelephony = createAsyncThunk('contract/fetchTelephony', async id => {
  const { data } = await getTelephony(id)

  return data
})

export const updatePaidCall = createAsyncThunk(
  'contract/updatePaidCall',
  async ({ idContract, phoneId, collectCall }) => {
    await togglePaidCall(idContract, { phoneId, collectCall })

    return collectCall
  }
)

export const updateInternet = createAsyncThunk(
  'contract/updateInternet',
  async ({ idContract, ssid, password }, { getState }) => {
    const body = { ...getState().contract.internet, ssid, wifiPassword: password }

    await updateWifi(idContract, body)

    return { ssid, password }
  }
)

export const unlockAccount = createAsyncThunk('contract/unlockAccount', async ({ idContract }) => {
  await unlockContracts(idContract)

  return toISO(new Date())
})

export const activateContractHero = createAsyncThunk(
  'contract/activateContractHero',
  async (contractId, { rejectWithValue }) => {
    try {
      const { data } = await activateHero(contractId)

      return data.heroLicence
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchConnectionStatus = createAsyncThunk(
  'contract/connectionStatus',
  async contractId => {
    const { data } = await getConnectionStatusRequest({ contractId })

    return data
  }
)

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    selectContract: (state, action) => {
      state.selected = action.payload
      state.connectionStatus = null
    },
    setInternetInfo: (state, action) => {
      state.internet = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchContractDetails.fulfilled, (state, action) => {
        state.detail = action.payload
      })
      .addCase(fetchContractDetails.rejected, state => {
        state.detail = {}
      })
      .addCase(fetchInternet.fulfilled, (state, action) => {
        state.internet = action.payload
      })
      .addCase(fetchInternet.rejected, state => {
        state.internet = { error: true }
      })
      .addCase(fetchTelephony.fulfilled, (state, action) => {
        state.telephony = action.payload
      })
      .addCase(fetchTelephony.rejected, state => {
        state.telephony = {}
      })
      .addCase(updatePaidCall.fulfilled, (state, action) => {
        state.telephony.collectCall = action.payload
      })
      .addCase(updatePaidCall.rejected, state => {
        state.telephony.collectCall = !state.telephony.collectCall
      })
      .addCase(updateInternet.fulfilled, (state, action) => {
        state.internet.ssid = action.payload.ssid
        state.internet.password = action.payload.password
      })
      .addCase(unlockAccount.fulfilled, (state, action) => {
        state.unlockDate = action.payload
      })
      .addCase(unlockAccount.rejected, state => {
        state.unlockDate = null
      })
      .addCase(activateContractHero.fulfilled, state => {
        state.selected.hasHeroActive = true
      })
      .addCase(fetchConnectionStatus.fulfilled, (state, action) => {
        state.connectionStatus = action.payload.status
      })
  },
})

export const { selectContract, setInternetInfo } = contractSlice.actions

export default contractSlice.reducer

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setDrawer } from 'slices/drawer'
import { fetchBankSlips } from 'slices/customer'
import { Button } from 'components/button'
import { FIREBASE_EVENTS, MY_BILLS_PAGE_TAB_LABEL, ROUTES } from 'app-constants'
import { STATICS } from 'statics'
import { useFirebase } from 'hooks'
import './style.scss'

export function Success({ contractId }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const { push } = useHistory()

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION_SUCCESS)
  }, [sendEvent])

  function handleClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SEE_BILLETS)
    dispatch(setDrawer(''))
    dispatch(fetchBankSlips(contractId))
    push(`${ROUTES.INVOICES.PATH}#${MY_BILLS_PAGE_TAB_LABEL.INVOICES}`)
  }

  return (
    <div className='container-success'>
      <div className='container-info'>
        <div className='container-icon'>
          <STATICS.SUCCESS />
        </div>
        <span className='title'>Negociação confirmada!</span>
        <div className='container-description'>
          <span className='description'>
            Esta negociação foi concluída e gerou um novo boleto que{' '}
            <strong>deve ser pago até o próximo dia útil</strong>
          </span>
          <span className='description'>
            Caso seu pagamento não seja identificado, esta{' '}
            <strong>negociação será cancelada</strong> e suas faturas voltarão a ser cobradas!
          </span>
          <span className='description'>
            Se a sua negociação foi parcelada, não se preocupe porque as demais parcelas virão junto
            com as faturas seguintes.
          </span>
        </div>
      </div>
      <div className='container-buttons'>
        <Button onClick={() => handleClick()} className='button accept' color='primary'>
          Ir para minhas faturas
        </Button>
      </div>
    </div>
  )
}

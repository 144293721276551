import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'components/button'
import { STATICS } from 'statics'
import { useFirebase } from 'hooks'
import { FIREBASE_EVENTS } from 'app-constants'
import { setStage } from 'slices/drawer'
import { STAGES } from '../../constants'
import './style.scss'

export function NewOptions({ setNextParcel }) {
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()

  function handleClickAccept() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_BILLET_NEGOTIATION_ACCEPT)

    setNextParcel(np => np + 1)
    dispatch(setStage(STAGES.OPTIONS))
  }

  function handleClickRefuse() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_BILLET_NEGOTIATION_REJECT)

    dispatch(setStage(STAGES.REFUSE_OPTIONS))
  }

  return (
    <div className='container-new-options'>
      <div className='container-info'>
        <span className='title'>Negociar Faturas</span>
        <div className='container-icon'>
          <STATICS.HANDS_SHAKE />
        </div>
        <span className='subtitle'>Aceita uma nova proposta?</span>
        <span className='description'>
          Clique em &quot;aceitar&quot; caso queira receber uma nova proposta de negociação. Clique
          em &quot;recusar&quot; se não quiser receber mais opções de negociação.
        </span>
      </div>
      <div className='container-buttons'>
        <Button onClick={() => handleClickAccept()} className='button accept' color='primary'>
          Aceitar
        </Button>
        <Button onClick={() => handleClickRefuse()} className='button refuse' outlined>
          Recusar
        </Button>
      </div>
    </div>
  )
}

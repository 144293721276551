export const VIRTUAL_SUPPORT_STAGES = {
  ADDRESS_CONFIRMATION: 'address_confirmation',
  AUTO_SUPPORT: 'auto_support',
  CHECK_MODEM_LIGHTS: 'check_modem_lights',
  INITIAL: 'initial',
  MASSIVE_WARNING: 'initial',
  VIRTUAL_SUPPORT_ALERT: 'initial',
  REDIRECT_CHAT: 'redirect_chat',
  SELECT_ADDRESS: 'select_address',
  SERVICE_ORDER: 'service_order',
  TALK_TO_ATTENDANT: 'talk_attendant',
  SCHEDULING_SUCCESS: 'scheduling_success',
  AFTER_TIME: 'after_time',
  SCHEDULING_SERVICE_ORDER: 'scheduling_service_order',
  SELECT_SERVICES: 'select_services',
  RESCHEDULE_CONFIRMATION: 'reschedule_confirmation',
  SCHEDULE_CONFIRMATION: 'schedule_confirmation',
  RESCHEDULING_SERVICE_ORDER: 'rescheduling_service_order',
  CANCEL_CONFIRMATION: 'cancel_confirmation',
  CANCEL_SUCCESS: 'cancel_success',
}

export const REDIRECT_VIRTUAL_SUPPORT_QUERY = 'redirectSuporteVirtual'

import React from 'react'
import { ContractSelect, Button } from 'components'
import { STATICS } from 'statics'
import {
  MY_BILLS_PAGE_TAB_LABEL,
  ROUTES,
  EVENTS,
  CALLS_STAGES,
  PLAN_STATUS_KEY,
  HAS_WIFI_CENTRAL,
  FIREBASE_EVENTS,
} from 'app-constants'
import { useSelector } from 'react-redux'
import { postEvent } from 'hooks'
import { MenuButton } from '../../partials'

export function DropdownMenu({ isOpenMenu, closeMenuHandler, handleClick, keycloak }) {
  const {
    selected: { id: idContract, contractStatus },
    telephony,
  } = useSelector(state => state.contract)
  const { id: phoneId } = telephony || {}

  function handleLogoutClick() {
    localStorage.removeItem('SUMICITY_SESSION_TIME')
    return keycloak?.logout()
  }

  function handleCloseMenuAndFirebaseEvent(fireBaseEvent) {
    closeMenuHandler()
    handleClick({ fireBaseEvent })
  }

  function handleAutoSupportClick() {
    closeMenuHandler()
    handleClick({ fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_VERIFY_CONNECTION })
    handleClick({ fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_MASSIVE })
    handleClick({ fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_OPEN_SERVICE_ORDER })
  }

  function renderAddressChange() {
    const isAccountActivated = contractStatus === PLAN_STATUS_KEY.ST_CONT_HABILITADO

    return (
      isAccountActivated && (
        <MenuButton
          className='item'
          onClick={() =>
            handleClick({
              drawer: 'addressChange',
              event: EVENTS.CLICK_ON_REGISTRATION_DATA,
              fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_ADDRESS_CHANGE,
            })
          }
        >
          Solicitar mudança de endereço
        </MenuButton>
      )
    )
  }

  return (
    <>
      <div className='container'>
        <button
          type='button'
          className='close'
          onClick={closeMenuHandler}
          data-cy='button-close-dropdown-menu'
        >
          <STATICS.CLOSE alt='Fechar Menu' className='icon' />
        </button>
        <div className='col'>
          <div className='title'>Minhas faturas</div>
          <MenuButton
            className='item'
            to={`${ROUTES.INVOICES.PATH}#${MY_BILLS_PAGE_TAB_LABEL.INVOICES}`}
            onClick={() => handleCloseMenuAndFirebaseEvent(FIREBASE_EVENTS.CLICK_ON_MENU_INVOICES)}
          >
            Notas fiscais
          </MenuButton>
          <div className='title'>Minha assinatura</div>
          <MenuButton
            className='item'
            onClick={() =>
              handleClick({ drawer: 'plan', fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_CONTRACT })
            }
          >
            Contrato
          </MenuButton>
        </div>
        <div className='col'>
          <div className='title'>Dados cadastrais</div>
          <MenuButton
            className='item'
            onClick={() =>
              handleClick({
                drawer: 'email',
                event: EVENTS.CLICK_ON_REGISTRATION_DATA,
                fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_SEE_MAILS,
              })
            }
          >
            Alterar e-mail
          </MenuButton>
          <MenuButton
            className='item'
            onClick={() =>
              handleClick({
                drawer: 'phone',
                event: EVENTS.CLICK_ON_REGISTRATION_DATA,
                fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_SEE_PHONES,
              })
            }
          >
            Alterar telefone
          </MenuButton>
          {renderAddressChange()}
        </div>
        <div className='col'>
          <div className='title'>Outros serviços</div>
          <MenuButton
            className='item'
            onClick={() =>
              handleClick({
                modal: 'unlock_account',
                fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_ACCOUNT_UNLOCK,
              })
            }
          >
            Desbloqueio de conta
          </MenuButton>
          {HAS_WIFI_CENTRAL && (
            <MenuButton
              className='item'
              onClick={() =>
                handleClick({
                  drawer: 'modem',
                  event: EVENTS.CLICK_ON_WIFI_PASSWORD,
                  fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_WIFI_CONFIGURATION,
                })
              }
            >
              Central do WIFI
            </MenuButton>
          )}
          <MenuButton
            className='item'
            onClick={() =>
              handleClick({
                drawer: 'password',
                fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_CHANGE_SAC_PASSWORD,
              })
            }
            dataCy='button-drawer-password'
          >
            Alterar senha
          </MenuButton>
          <MenuButton
            className='item'
            to={`${ROUTES.TECHNICAL_SUPPORT.PATH}#Internet`}
            onClick={handleAutoSupportClick}
          >
            Auto Suporte
          </MenuButton>
        </div>
        <div className='col'>
          <div className='title'>&nbsp;</div>
          <MenuButton
            className='item'
            onClick={() =>
              handleClick({
                drawer: 'services',
                fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_ACCOUNT_INFORMATION,
              })
            }
          >
            Meus serviços
          </MenuButton>
          <MenuButton
            className='item'
            onClick={() => {
              handleClick({
                drawer: 'faq',
                fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_FAQ,
              })
              postEvent(idContract, EVENTS.FAQ_CONSULTATION)
            }}
          >
            Perguntas frequentes
          </MenuButton>
          <MenuButton className='item' to='/documentos' onClick={closeMenuHandler}>
            Documentos
          </MenuButton>
        </div>
        <div className='col'>
          {!!phoneId && (
            <>
              <div className='title'>Telefone</div>
              <MenuButton
                className='item'
                onClick={() =>
                  handleClick({
                    drawer: 'calls',
                    event: EVENTS.CLICK_ON_CALLS,
                    fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_CALLS,
                  })
                }
              >
                Minhas ligações
              </MenuButton>
              <MenuButton
                className='item'
                onClick={() =>
                  handleClick({
                    drawer: 'calls',
                    stage: CALLS_STAGES.HISTORY,
                    fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_CALLS_HISTORY,
                  })
                }
              >
                Histórico de ligações
              </MenuButton>
              <MenuButton
                className='item'
                onClick={() =>
                  handleClick({
                    drawer: 'calls',
                    stage: CALLS_STAGES.EXTRACT,
                    fireBaseEvent: FIREBASE_EVENTS.CLICK_ON_MENU_CALLS_EXTRACT,
                  })
                }
              >
                Extrato de ligações
              </MenuButton>
            </>
          )}

          <div className='title'>Selecionar contrato</div>
          <ContractSelect className='item' />
          <Button className='leave' onClick={handleLogoutClick} color='primary'>
            Sair
          </Button>
        </div>
      </div>
      {isOpenMenu && <div className='overlay' onClick={closeMenuHandler} />}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setStage } from 'slices/drawer'
import { FIREBASE_EVENTS } from 'app-constants'
import { formatCurrency, formatDate } from 'utils'
import { Button } from 'components/button'
import { Input } from 'components/input'
import { useFirebase } from 'hooks'
import { STAGES } from '../../constants'
import { TotalNegotiationCard } from '../total-card'
import './style.scss'

export function NegotiationOptions({ invoices, parcels, nextParcel, setSelectedOption }) {
  const [isSelected, setIsSelected] = useState(false)
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const parcel = parcels[nextParcel]

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.BILLET_NEGOTIATION)
  }, [sendEvent])

  function handleAcceptClick() {
    setSelectedOption(parcel)
    dispatch(setStage(STAGES.CONFIRMATION))
  }

  function handleRejectClick() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_BILLET_NEGOTIATION_REJECT_NEGOTIATION)

    if (nextParcel + 1 >= parcels.length) {
      dispatch(setStage(STAGES.REFUSE_OPTIONS))
    } else {
      dispatch(setStage(STAGES.NEW_OPTIONS_LIST))
    }
  }

  function handleCheckboxChange() {
    setIsSelected(selected => !selected)
  }

  function renderParcelValue() {
    const { quantity, firstParcelValue, parcelValue } = parcel

    if (quantity > 1) {
      return (
        <span className='option-value'>
          {formatCurrency(firstParcelValue)} + {quantity - 1} x {formatCurrency(parcelValue)}
        </span>
      )
    }

    return (
      <span className='option-value'>
        {quantity} x {formatCurrency(firstParcelValue)}
      </span>
    )
  }

  function renderDueDate() {
    const { dueDate, quantity } = parcel
    const dueDateString = formatDate(dueDate, 'dd/MM')

    if (quantity === 1) {
      return <span className='option-due-date'>Vencimento em {dueDateString}</span>
    }

    return <span className='option-due-date'>venc. 1ª parcela em {dueDateString}</span>
  }

  return (
    <span className='negotiation-options'>
      <h3 className='title'>Negociar Faturas</h3>
      <TotalNegotiationCard invoices={invoices} />
      <div className='description-container'>
        <h4 className='title'>Opções da Pagamento</h4>
        <p className='description'>
          Selecione a opção de pagamento abaixo para negociar suas faturas em aberto.
        </p>
      </div>
      <div className='option-container'>
        <div className='option-card'>
          <Input
            onChange={handleCheckboxChange}
            name='select-parcel'
            className='option-checkbox'
            type='checkbox'
            value={isSelected}
          />
          <div className='option-info'>
            {renderDueDate()}
            {renderParcelValue()}
          </div>
        </div>
      </div>
      <div className='button-container'>
        <Button
          disabled={!isSelected}
          className='button'
          color='primary'
          onClick={handleAcceptClick}
        >
          Negociar
        </Button>
        <Button className='button refuse' color='primary' outlined onClick={handleRejectClick}>
          Rejeitar negociação
        </Button>
      </div>
    </span>
  )
}

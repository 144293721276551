import React, { useState, useEffect, useMemo } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector, useDispatch } from 'react-redux'
import {
  PLAN_STATUS,
  MY_SERVICES,
  TV_PLAN_CARDS,
  TV_PLAN_EVENTS,
  COMPONENTS_RENDER,
  DYNAMIC_RENDER_COMPONENTS,
} from 'app-constants'
import { Card, Button, Alert } from 'components'
import { getGloboplayLink, postEvent } from 'hooks'
import { STATICS } from 'statics'
import { fetchContractDetails } from 'slices/contract'
import { downloadContractTerm } from 'utils'
import { Pending } from './components'
import { GO_TO_WEBSITE_TEXT, SEE_DETAILS_TEXT, TV_PLAN_CARD_INITIAL_STATE } from './constants'
import './style.scss'

const SHOW_TV_PASSWORD_TOOLTIP_TEXT = 'Mostrar senha'
const HIDE_TV_PASSWORD_TOOLTIP_TEXT = 'Esconder senha'

export function PlanDetails() {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState('')
  const [showTvPassword, setShowTvPassword] = useState(false)
  const [tvPlanCardLinks, setTvPlanCardLinks] = useState(TV_PLAN_CARD_INITIAL_STATE)
  const { selected: contract, detail: contractDetails } = useSelector(({ contract }) => contract)
  const dispatch = useDispatch()

  const title = useMemo(() => {
    if (pending)
      return (
        <h3 className='title'>
          Acompanhe o status <br />
          do seu pedido
        </h3>
      )

    return (
      <h3 className='title'>
        Detalhamento <br />
        do meu plano
      </h3>
    )
  }, [pending])

  useEffect(() => {
    if (!contractDetails) dispatch(fetchContractDetails(contract.id))
    if (contractDetails?.status === PLAN_STATUS.ST_CONT_EM_ATIVACAO) setPending(true)

    async function getGloboLink() {
      try {
        const params = { contractId: contract.id, contractItem: TV_PLAN_CARDS.GLOBO_PLAY }
        const { data } = await getGloboplayLink(params)

        setTvPlanCardLinks(tvLinks => ({
          ...tvLinks,
          [TV_PLAN_CARDS.GLOBO_PLAY]: data.accessLink,
        }))
      } catch (err) {
        console.error(err)
      }
    }

    async function getPremiereLink() {
      try {
        const params = { contractId: contract.id, contractItem: TV_PLAN_CARDS.PREMIERE }
        const { data } = await getGloboplayLink(params)

        setTvPlanCardLinks(tvLinks => ({
          ...tvLinks,
          [TV_PLAN_CARDS.PREMIERE]: data.accessLink,
        }))
      } catch (err) {
        console.error(err)
      }
    }

    async function getTelecineCardLink() {
      try {
        const params = { contractId: contract.id, contractItem: TV_PLAN_CARDS.TELECINE }
        const { data } = await getGloboplayLink(params)

        setTvPlanCardLinks(tvLinks => ({
          ...tvLinks,
          [TV_PLAN_CARDS.TELECINE]: data.accessLink,
        }))
      } catch (err) {
        console.error(err)
      }
    }

    async function getGloboplayPlusLink() {
      try {
        const params = {
          contractId: contract.id,
          contractItem: TV_PLAN_CARDS.GLOBO_PLAY_MAIS_CANAIS,
        }
        const { data } = await getGloboplayLink(params)

        setTvPlanCardLinks(tvLinks => ({
          ...tvLinks,
          [TV_PLAN_CARDS.GLOBO_PLAY_MAIS_CANAIS]: data.accessLink,
        }))
      } catch (err) {
        console.error(err)
      }
    }

    function getGloboPlayAdditional() {
      const hasGloboPlayAdditional = contractDetails?.contractAdditionals.some(
        ({ itemCode }) => itemCode === TV_PLAN_CARDS.GLOBO_PLAY
      )
      const hasPremiere = contractDetails?.contractAdditionals.some(
        ({ itemCode }) => itemCode === TV_PLAN_CARDS.PREMIERE
      )
      const hasTelecine = contractDetails?.contractAdditionals.some(
        ({ itemCode }) => itemCode === TV_PLAN_CARDS.TELECINE
      )
      const hasGloboplayPlus = contractDetails?.contractAdditionals.some(
        ({ itemCode }) => itemCode === TV_PLAN_CARDS.GLOBO_PLAY_MAIS_CANAIS
      )

      if (hasGloboPlayAdditional) getGloboLink()
      if (hasPremiere) getPremiereLink()
      if (hasTelecine) getTelecineCardLink()
      if (hasGloboplayPlus) getGloboplayPlusLink()
    }

    getGloboPlayAdditional()
  }, [contractDetails, contract.id, dispatch])

  function handleOpenTerm() {
    downloadContractTerm(contract.id, setError)
  }

  function handleAlertClose() {
    setError(null)
  }

  function handleClickShowTvPassword() {
    setShowTvPassword(!showTvPassword)
  }

  function handleOpenAdditionalLink(eventName) {
    if (eventName) postEvent(contract.id, eventName)
  }

  function getIsTvPlanCardAdditional(itemName) {
    return Object.values(TV_PLAN_CARDS).includes(itemName)
  }

  function getAdditionalInfo(itemCode, urlDocument) {
    const isTvPlanAdditional = getIsTvPlanCardAdditional(itemCode)
    const event = isTvPlanAdditional ? TV_PLAN_EVENTS[itemCode] : MY_SERVICES[itemCode].eventName
    const link = isTvPlanAdditional ? tvPlanCardLinks[itemCode] : urlDocument
    const buttonText = isTvPlanAdditional ? GO_TO_WEBSITE_TEXT : SEE_DETAILS_TEXT
    const hasGloboPlayPdf = isTvPlanAdditional && urlDocument

    return { event, link, buttonText, hasGloboPlayPdf }
  }

  function renderAdditionals() {
    if (!contractDetails.contractAdditionals.length) return null
    return (
      <>
        <p className='subtitle'>Serviços adicionais</p>
        {contractDetails.contractAdditionals.map(
          ({ itemCode, urlDocument, urlLogo, urlImage, itemName }) => {
            const { event, link, buttonText, hasGloboPlayPdf } = getAdditionalInfo(
              itemCode,
              urlDocument
            )

            const urlSvaLogo = urlLogo || urlImage

            return (
              <Card
                className={`card -additional -flex ${hasGloboPlayPdf && '-globoplay'}`}
                key={itemCode}
              >
                <div className='container-info'>
                  {urlSvaLogo && <img src={urlSvaLogo} alt={itemName} className='img' />}
                  <p className='text'>{itemName}</p>
                </div>
                <div className='container-buttons'>
                  {link && (
                    <a href={link} rel='noreferrer' target='_blank'>
                      <Button
                        color='primary'
                        outlined
                        className='see-details'
                        data-cy='button-see-details'
                        onClick={() => handleOpenAdditionalLink(event)}
                      >
                        {buttonText}
                      </Button>
                    </a>
                  )}
                  {hasGloboPlayPdf && (
                    <a href={urlDocument} rel='noreferrer' target='_blank'>
                      <Button
                        color='primary'
                        outlined
                        className='see-details globoplay'
                        data-cy='button-see-details-globoplay'
                        onClick={() => handleOpenAdditionalLink(urlDocument, event)}
                      >
                        {SEE_DETAILS_TEXT}
                      </Button>
                    </a>
                  )}
                </div>
              </Card>
            )
          }
        )}
      </>
    )
  }

  function renderShowTvPasswordButton() {
    return (
      <>
        <div
          className='icon'
          data-for='tv-password-tip'
          data-tip={showTvPassword ? HIDE_TV_PASSWORD_TOOLTIP_TEXT : SHOW_TV_PASSWORD_TOOLTIP_TEXT}
        >
          {showTvPassword ? (
            <STATICS.EYE onClick={handleClickShowTvPassword} />
          ) : (
            <STATICS.EYE_SLASH onClick={handleClickShowTvPassword} />
          )}
        </div>
        <ReactTooltip id='tv-password-tip' />
      </>
    )
  }

  function renderDownloadButton() {
    const shouldRenderDownloadButton =
      COMPONENTS_RENDER[DYNAMIC_RENDER_COMPONENTS.CONTRACT_PDF_DOWNLOAD]

    return (
      shouldRenderDownloadButton && (
        <Button color='primary' className='button' outlined onClick={handleOpenTerm}>
          Download do PDF
        </Button>
      )
    )
  }

  if (!contractDetails) return null

  return (
    <div className='plan-details-component'>
      {title}
      {!!error && (
        <Alert
          className='alert'
          severity='error'
          size='large'
          title={error.title}
          text={error.text}
          onClose={handleAlertClose}
        />
      )}
      {pending && <Pending />}

      <p className='subtitle'>Plano contratado</p>
      <Card className='card'>
        <p className='text'>{contractDetails.planName}</p>
        <p className='text'>Valor: {contractDetails.formattedTotalPrice}</p>
        {contractDetails.additionalTvPointsQuantity > 0 && (
          <p className='text'>
            {contractDetails.additionalTvPointsQuantity} Ponto opcional (HD):{' '}
            {contractDetails.formattedAdditionalTvPointsTotalPrice}
          </p>
        )}
        <p className='text'>Status: {PLAN_STATUS[contract.contractStatus]}</p>
      </Card>

      {!!contractDetails.tvPassword && (
        <>
          <p className='subtitle'>Login da TV | Sumicity Play</p>
          <Card className='card'>
            <p className='text'>Usuário: {contractDetails.tvLogin}</p>
            <div className='tv-password-container'>
              <p className='text'>Senha :&nbsp;</p>
              <p className={showTvPassword ? 'password' : 'password -hidden'}>
                {contractDetails.tvPassword}
              </p>
              {renderShowTvPasswordButton()}
            </div>
          </Card>
        </>
      )}

      {renderAdditionals()}
      <Card className='card -contract -flex'>
        <div className='content'>
          <p className='title'>Meu contrato</p>
          <p className='text'>{contractDetails.planName}</p>
        </div>
        {!pending && renderDownloadButton()}
      </Card>
    </div>
  )
}

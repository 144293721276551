export const TV_PLAN_CARDS = {
  GLOBO_PLAY: 'GLOBO_PLAY',
  PREMIERE: 'PREMIERE',
  TELECINE: 'TELECINE',
  GLOBO_PLAY_MAIS_CANAIS: 'GLOBO_PLAY_MAIS_CANAIS',
  GIGA_TV: 'GIGA_TV',
}

export const TV_PLAN_EVENTS = {
  [TV_PLAN_CARDS.GLOBO_PLAY]: 'CLICK_ON_GLOBOPLAY_LINK',
  [TV_PLAN_CARDS.PREMIERE]: 'CLICK_ON_SVA_TELECINE',
  [TV_PLAN_CARDS.TELECINE]: 'CLICK_ON_PREMIERE_LINK',
  [TV_PLAN_CARDS.GLOBO_PLAY_MAIS_CANAIS]: 'CLICK_ON_SVA_GLOBO_PLAY_MAIS_CANAIS',
  [TV_PLAN_CARDS.GIGA_TV]: 'CLICK_ON_SVA_GIGA_TV',
}

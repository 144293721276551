import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchContractDetails } from 'slices/contract'
import { FIREBASE_EVENTS } from 'app-constants'
import { Card } from 'components'
import { useFirebase } from 'hooks'
import './style.scss'

export function MyServices() {
  const { sendEvent } = useFirebase()
  const {
    detail,
    selected: { id: idContract },
  } = useSelector(({ contract }) => contract)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchContractDetails(idContract))
  }, [idContract, dispatch])

  function renderServiceCards() {
    sendEvent(FIREBASE_EVENTS.CLICK_ON_SVA_DETAILS)
    if (detail?.contractAdditionals.length > 0) {
      return detail?.contractAdditionals.map(item => {
        const { itemName, urlImage, urlLogo, description } = item
        return (
          <Card key={itemName} className='card'>
            <img src={urlLogo || urlImage} className='img' alt={itemName} />
            <div className='content'>
              <p className='title'>{itemName}</p>
              <p className='info'>{description}</p>
            </div>
          </Card>
        )
      })
    }
    return <p className='message'>O contrato selecionado não possui serviços contratados.</p>
  }
  if (!detail) return null

  return (
    <div className='my-services-component'>
      <h3 className='title'>Meus serviços</h3>
      <h5 className='subtitle'>
        Seus serviços estão listados abaixo, caso deseja obter mais informações ou configurar algo,
        acesse o site dos serviços contratados.
      </h5>
      <p className='text'>Meus serviços</p>
      {renderServiceCards()}
    </div>
  )
}

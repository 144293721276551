import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FIREBASE_EVENTS, MY_BILLS_PAGE_TAB_LABEL, ROUTES } from 'app-constants'
import { sendWhatsappMessage } from 'utils'
import { Button } from 'components/button'
import { setDrawer } from 'slices/drawer'
import { useFirebase } from 'hooks'
import { ALERT_THEME, ALERT_THEME_CONTENT, BUTTON_CONTENT, BUTTON_THEME } from '../../constants'
import './style.scss'

export function AddressChangeAlert({ theme, data }) {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const { sendEvent } = useFirebase()
  const { id: idContract, unity } = useSelector(state => state.contract.selected)
  const { ICON, TITLE, DESCRIPTION, BUTTON } = ALERT_THEME_CONTENT[theme]

  useEffect(() => {
    if (ALERT_THEME.SUCCESS === theme) {
      sendEvent(FIREBASE_EVENTS.ADDRESS_CHANGE_SUCCESS)
    } else {
      sendEvent(FIREBASE_EVENTS.ADDRESS_CHANGE_ERROR)
    }
  }, [sendEvent, theme])

  function handleClickGoToInvoices() {
    push(`${ROUTES.INVOICES.PATH}#${MY_BILLS_PAGE_TAB_LABEL.BANKSLIPS}`)
  }

  function handleClickWhatsAppSupport() {
    const { street, number, city } = data
    const message =
      street && number && city
        ? `[${idContract}] Olá :) desejo fazer uma mudança de endereço para: ${street}, ${number} - ${city}`
        : `[${idContract}] Olá :)`

    sendWhatsappMessage(message, unity)
  }

  function renderButton() {
    const { ICON: ICON_BUTTON, TEXT } = BUTTON_CONTENT[BUTTON]
    const HANDLE_CLICK = {
      [BUTTON_THEME.GO_TO_INVOICES]: handleClickGoToInvoices,
      [BUTTON_THEME.WHATS_APP_SUPPORT]: handleClickWhatsAppSupport,
    }

    return (
      BUTTON && (
        <Button
          type='button'
          color='primary'
          icon={ICON_BUTTON}
          onClick={() => {
            HANDLE_CLICK[BUTTON]()
            dispatch(setDrawer(''))
          }}
        >
          {TEXT}
        </Button>
      )
    )
  }

  return (
    <div className='address-change-alert-component'>
      <div className='icon'>{ICON}</div>
      <h2 className='title'>{TITLE}</h2>
      <p className='description'>{DESCRIPTION}</p>
      {BUTTON && renderButton()}
    </div>
  )
}

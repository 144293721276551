import React from 'react'
import { AddressChangeDrawer } from './address-change'
import { EmailDrawer } from './email'
import { PlanDrawer } from './plan'
import { PhoneDrawer } from './phone'
import { CallsDrawer } from './calls'
import { MyServicesDrawer } from './my-services'
import { ModemDrawer } from './modem'
import { PasswordDrawer } from './password'
import { CompleteSignupDrawer } from './complete-signup'
import { DueDateDrawer } from './due-date'
import { MessagesDrawer } from './messages'
import { VirtualSupportDrawer } from './virtual-support'
import { ActivationPendingDrawer } from './activation-pending'
import { MyTicketsDrawer } from './my-tickets'
import { NegotiationDrawer } from './negotiation'
import { FaqDrawer } from './faq'

export function DrawerTemplate() {
  return {
    email: <EmailDrawer />,
    plan: <PlanDrawer />,
    phone: <PhoneDrawer />,
    calls: <CallsDrawer />,
    services: <MyServicesDrawer />,
    modem: <ModemDrawer />,
    password: <PasswordDrawer />,
    complete: <CompleteSignupDrawer />,
    dueDate: <DueDateDrawer />,
    messages: <MessagesDrawer />,
    virtualSupport: <VirtualSupportDrawer />,
    addressChange: <AddressChangeDrawer />,
    activationPending: <ActivationPendingDrawer />,
    tickets: <MyTicketsDrawer />,
    negotiation: <NegotiationDrawer />,
    faq: <FaqDrawer />,
  }
}
